import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-shop-myaccount',
  templateUrl: './shop-myaccount.component.html',
  styleUrls: ['./shop-myaccount.component.css']
})

/**
 * Shop MyAccount Component
 */
export class ShopMyaccountComponent implements OnInit {

  /**
   * Header button list show
   */
  //shopPages = true;
  //Menuoption = 'shop'
  //footerVariant = 'footer-two';
  loginStatus = false;

  constructor(private router: Router) {
    if (!(sessionStorage.getItem('login-status') === 'loggedIn')) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(): void {


  }

}
