<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-md-4">
                <div class="d-flex align-items-center">
                    <img src="assets/images/client/nisa.jpg" class="avatar avatar-md-md rounded-circle" alt="">
                    <div class="ms-3">
                        <h6 class="text-muted mb-0">Hello,</h6>
                        <h5 class="mb-0">Nisa Admin</h5>
                    </div>
                </div>
            </div>
        </div><!--end row-->

        <div class="row">
            <div class="d-flex">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills flex-column rounded mt-4 shadow p-3 mb-0 col-sm-3"
                    orientation="vertical">
                    <!-- <li ngbNavItem="top" class="nav-item">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start py-1 px-3">
                                <h6 class="mb-0"><i class="uil uil-dashboard h5 align-middle me-2 mb-0"></i> Dashboard
                                </h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                                aria-labelledby="proposal">
                                <h6 class="text-muted">Hello <span class="text-dark">Nisa Admin</span>
                                </h6>
                                 <h6 class="text-muted mb-0">From your account dashboard you can view your <a
                                        href="javascript:void(0)" class="text-danger">recent orders</a>, manage your <a
                                        href="javascript:void(0)" class="text-danger">shipping and billing
                                        addresses</a>, and <a href="javascript:void(0)" class="text-danger">edit your
                                        password and account details</a>.</h6> 
                            </div>
                        </ng-template>
                    </li> -->
                    <li ngbNavItem="middle" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded" (click)="applyFilter($event.target.value)">
                            <div class="text-start py-1 px-3">
                                <h6 class="mb-0"><i class="uil uil-list-ul h5 align-middle me-2 mb-0"></i> Registrations
                                </h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <form>
                                <div class="tab-pane fade show active p-4 rounded shadow" id="registrationTable"
                                    role="tabpanel" aria-labelledby="proposal">

                                    <div class="row align-items-end">
                                        <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                            <h3 class="title mb-0">All Registrations</h3><small
                                                class="text-muted h6 me-2">{{this.dataSource?.filteredData?.length}}
                                                Registration/s</small>
                                            <mat-form-field>
                                                <div class="form-icon position-relative col-md-4 pt-4">
                                                    <input class="form-control " matInput
                                                        (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                                                </div>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-5 text-md-end text-center">
                                            <ul class="list-unstyled social-icon social mb-0 mt-4">
                                                <!-- <li class="list-inline-item">
                                                    <a data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                        title="Load More" class="rounded" href="javascript:void(0)"
                                                        (click)="goForward()">
                                                        <i class="uil uil-plus-circle align-middle"></i>
                                                    </a>
                                                </li> -->
                                                <li class="list-inline-item">
                                                    <a data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                        title="Refresh" class="rounded" href="javascript:void(0)"
                                                        (click)="refreshTable()">
                                                        <i class="uil uil-refresh align-middle"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show active p-4 mt-4 rounded shadow">
                                        <table mat-table [dataSource]="dataSource" matSort
                                            (matSortChange)="refreshData()">

                                            <ng-container matColumnDef="regNo">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header> Reg No
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row"> {{row.regNo}} </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="event">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header> Event
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row"> {{row.eventName}} </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header> Name
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="paymentStatus">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header> Payment Status
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row" [style.color]="row.color">
                                                    {{row.paymentStatus}} </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="timestamp">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row"> {{row.timestamp}} </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="action">
                                                <mat-header-cell *matHeaderCellDef scope="col" class="font-weight-bold">
                                                    Action
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let row"> <a href="javascript:void(0)"
                                                        class="btn btn-sm btn-pills btn-warning mt-2 me-2"
                                                        (click)="viewRegistration(row)"
                                                        [ngxScrollTo]="'#registrationDetails'">View</a>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: displayedColumns;">
                                            </mat-row>
                                        </table>

                                        <mat-paginator [pageSize]="itemsPerPage"
                                            [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
                                        <!-- <div class="buttons">
                                            <button mat-button (click)="goBackward()"
                                                [disabled]="currentPage === 0">Backward</button>
                                            <button mat-button (click)="goForward()"
                                                [disabled]="!lastEvaluatedKey">Forward</button>
                                        </div> -->
                                    </div>
                                    <div *ngIf="selectedRegistration" id="registrationDetails"
                                        class="tab-pane fade show active p-4 mt-4 rounded shadow" role="tabpanel"
                                        aria-labelledby="proposal">
                                        <form>
                                            <div class="modal-header border-bottom">
                                                <h5 id="LoginForm-title" class="modal-title"> Registration Details</h5>
                                                <button type="button" data-bs-dismiss="modal" id="close-modal"
                                                    (click)="selectedRegistration = null"
                                                    [ngxScrollTo]="'#registrationTable'" class="btn btn-icon btn-close">
                                                    <i class="uil uil-times fs-4 text-dark"></i>
                                                </button>
                                            </div>
                                            <div class="row pt-4">
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Registration Id</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="user"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input name="name" id="first-name" type="text"
                                                                class="form-control ps-5"
                                                                [value]="selectedRegistration?.registrationId" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Payment Ref</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="user"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input name="name" id="first-name" type="text"
                                                                class="form-control ps-5" id="paymentRefCtrl"
                                                                [value]="selectedRegistration?.paymentRef">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Payment Status</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="check-circle"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <select class="form-control ps-5"
                                                                aria-label="Default select example"
                                                                id="paymentStatusCtrl" name="paymentStatus">
                                                                <option slected>{{selectedRegistration?.paymentStatus}}
                                                                </option>
                                                                <option
                                                                    *ngIf="selectedRegistration?.paymentStatus != 'VERIFIED'">
                                                                    VERIFIED</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Name</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="user"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input name="name" id="first-name" type="text"
                                                                class="form-control ps-5"
                                                                [value]="selectedRegistration?.name" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end col-->
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Phone</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="phone"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input name="name" id="last-name" type="text"
                                                                class="form-control ps-5"
                                                                [value]="selectedRegistration?.phone" disabled>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!--end col-->
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Email</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="mail"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input name="email" id="email" type="email"
                                                                class="form-control ps-5"
                                                                [value]="selectedRegistration?.email" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Skill Level</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="zap" class="fea icon-sm icons"></i-feather>
                                                            <input name="name" id="last-name" type="text"
                                                                class="form-control ps-5"
                                                                [value]="selectedRegistration?.skillLevel" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Event Name</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="bookmark"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input type="text" class="form-control ps-5"
                                                                [value]="selectedRegistration?.eventTitle" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Month</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="calendar"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input type="text" class="form-control ps-5"
                                                                [value]="selectedRegistration?.eventMonth" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Fee</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="dollar-sign"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input type="text" class="form-control ps-5"
                                                                [value]="selectedRegistration?.eventFee" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Preferred Slot #1</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="clock"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input type="text" class="form-control ps-5"
                                                                [value]="selectedRegistration?.timeSlotOne" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Preferred Slot #2</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="clock"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input type="text" class="form-control ps-5"
                                                                [value]="selectedRegistration?.timeSlotTwo" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Timestamp</label>
                                                        <div class="form-icon position-relative">
                                                            <i-feather name="watch"
                                                                class="fea icon-sm icons"></i-feather>
                                                            <input type="text" class="form-control ps-5"
                                                                [value]="selectedRegistration?.timestamp" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end col-->

                                                <div class="col-lg-12 mt-2 mb-0">
                                                    <button class="btn btn-primary" (click)="saveChanges()">Save
                                                        Changes</button>
                                                </div>
                                                <!--end col-->
                                            </div>
                                            <!--end row-->
                                        </form>
                                    </div>
                                </div>
                            </form>

                        </ng-template>
                    </li>
                    <!-- <li ngbNavItem="bottom" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start py-1 px-3">
                                <h6 class="mb-0"><i class="uil uil-arrow-circle-down h5 align-middle me-2 mb-0"></i>
                                    Downloads</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                                aria-labelledby="proposal">
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="border-bottom">Product Name</th>
                                                <th scope="col" class="border-bottom">Description</th>
                                                <th scope="col" class="border-bottom">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Quick heal</th>
                                                <td class="text-muted">It is said that song composers of the past <br>
                                                    used dummy texts as
                                                    lyrics when writing <br> melodies in order to have a 'ready-made'
                                                    <br> text to sing with the
                                                    melody.
                                                </td>
                                                <td class="text-success">Downloaded</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="general" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start py-1 px-3">
                                <h6 class="mb-0"><i class="uil uil-map-marker h5 align-middle me-2 mb-0"></i> Addresses
                                </h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                                aria-labelledby="proposal">
                                <h6 class="text-muted mb-0">The following addresses will be used on the checkout page by
                                    default.</h6>

                                <div class="row">
                                    <div class="col-lg-6 mt-4 pt-2">
                                        <div class="d-flex align-items-center mb-4 justify-content-between">
                                            <h5 class="mb-0">Billing Address:</h5>
                                            <a href="javascript:void(0)" class="text-primary h5 mb-0"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title=""
                                                data-original-title="Edit"><i class="uil uil-edit align-middle"></i></a>
                                        </div>
                                        <div class="pt-4 border-top">
                                            <p class="h6">Cally Joseph</p>
                                            <p class="h6 text-muted">C/54 Northwest Freeway, </p>
                                            <p class="h6 text-muted">Suite 558,</p>
                                            <p class="h6 text-muted">Houston, USA 485</p>
                                            <p class="h6 text-muted mb-0">+123 897 5468</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-4 pt-2">
                                        <div class="d-flex align-items-center mb-4 justify-content-between">
                                            <h5 class="mb-0">Shipping Address:</h5>
                                            <a href="javascript:void(0)" class="text-primary h5 mb-0"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title=""
                                                data-original-title="Edit"><i class="uil uil-edit align-middle"></i></a>
                                        </div>
                                        <div class="pt-4 border-top">
                                            <p class="h6">Cally Joseph</p>
                                            <p class="h6 text-muted">C/54 Northwest Freeway, </p>
                                            <p class="h6 text-muted">Suite 558,</p>
                                            <p class="h6 text-muted">Houston, USA 485</p>
                                            <p class="h6 text-muted mb-0">+123 897 5468</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem="medicine" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded">
                            <div class="text-start py-1 px-3">
                                <h6 class="mb-0"><i class="uil uil-user h5 align-middle me-2 mb-0"></i> Account Details
                                </h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane fade show active p-4 rounded shadow" id="developing" role="tabpanel"
                                aria-labelledby="proposal">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">First Name</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                    <input name="name" id="first-name" type="text"
                                                        class="form-control ps-5" value="Cally">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Last Name</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input name="name" id="last-name" type="text"
                                                        class="form-control ps-5" value="Joseph">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Your Email</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input name="email" id="email" type="email"
                                                        class="form-control ps-5" value="callyjoseph@gmail.com">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Display Name</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="user-check" class="fea icon-sm icons"></i-feather>
                                                    <input name="name" id="display-name" type="text"
                                                        class="form-control ps-5" value="cally_joseph">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mt-2 mb-0">
                                            <button class="btn btn-primary">Save Changes</button>
                                        </div>
                                    </div>
                                </form>

                                <h5 class="mt-4">Change password :</h5>
                                <form>
                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">Old password :</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" class="form-control ps-5"
                                                        placeholder="Old password" required="">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">New password :</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" class="form-control ps-5"
                                                        placeholder="New password" required="">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <label class="form-label">Re-type New password :</label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                                    <input type="password" class="form-control ps-5"
                                                        placeholder="Re-type New password" required="">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 mt-2 mb-0">
                                            <button class="btn btn-primary">Save Password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </li> -->
                    <li ngbNavItem="orthopadic" class="nav-item mt-2">
                        <a ngbNavLink class="nav-link rounded" (click)="logout()">
                            <div class="text-start py-1 px-3">
                                <h6 class="mb-0"><i class="uil uil-sign-out-alt h5 align-middle me-2 mb-0"></i> Logout
                                </h6>
                            </div>
                        </a>
                    </li>
                </ul>

                <div [ngbNavOutlet]="nav" class="ms-4 mt-4 col-md-10"></div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->