import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  authForm!: UntypedFormGroup;
  submitted = false;
  isLoggedIn = false;
  year = new Date().getFullYear()

  constructor(private formBuilder: UntypedFormBuilder, private router: Router) { }



  ngOnInit(): void {
    //localStorage.setItem('login-status', '');
    this.authForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]

    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.authForm.invalid) {
      sessionStorage.removeItem('login-status');
      return;
    } else {
      if (this.authForm?.value?.user === 'admin' && this.authForm?.value?.password === 'Nisa@2022') {
        this.isLoggedIn = true;
        sessionStorage.setItem('login-status', 'loggedIn');
        this.router.navigate(['/admin']);
      }
    }
  }

  onChange() {
    this.submitted = false;
  }

  get form() { return this.authForm.controls; }

}
