<!-- <section class="section pb-0" *ngIf="trasactionStatus==='COMPLETED'">
    <div class=" p-4">
        <div role="alert" class="alert alert-success mb-0">
            <div class="text-center">
                <span *ngFor="let number of [1, 2, 3, 4, 5]">
                    <i-feather name="star" class="fea icon-xl icons"></i-feather>
                </span>
            </div>
            <h4 class="alert-heading text-center">Thank you
                for the payment, You have succefully completed registration
                process</h4>
            <p> Your registration id is {{registrationData.transRef}}</p>
            <p class="mb-0 border-top pt-3">Please note this number somewhere for future reference.</p>
            <div class="mt-4 text-center">
                <a (click)="refresh()" class="btn btn-outline-primary rounded"><i class="fa-solid fa-flag"></i>
                    New Registration</a>
            </div>
        </div>
    </div>
</section> -->
<!-- <section class="bg-home bg-light d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="text-center">
                    <div _ngcontent-rat-c231=""
                        class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                        style="height: 90px; width: 90px;"><i _ngcontent-rat-c231=""
                            class="uil uil-thumbs-up align-middle h1 mb-0"></i></div>
                    <h1 class="my-4 fw-bold">Thank You</h1>
                    <p class="text-muted para-desc mx-auto">Launch your campaign and benefit from
                        our expertise on designing and managing conversion centered bootstrap v5 html page.</p><a
                        routerlink="/index" class="btn btn-soft-primary mt-3" ng-reflect-router-link="/index"
                        href="/index">Go To Home</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="section pb-0">
    <div class="container">
        <div class="text-center">
            <div _ngcontent-rat-c231=""
                class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                style="height: 90px; width: 90px;"><i _ngcontent-rat-c231=""
                    class="uil uil-thumbs-up align-middle h1 mb-0"></i></div>
            <h3 class="my-4 fw-bold">Thank You For The Registration</h3>
        </div>
        <div class="row mt-5 pt-4 pt-sm-0 justify-content-center" #printableContent>
            <div class="col-lg-10">
                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        <div class="invoice-top pb-4 border-bottom">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="logo-invoice mb-2">Nisa<span class="text-primary"></span></div>
                                </div>
                                <div class="col-md-4 mt-4 mt-sm-0">
                                    <h5 _ngcontent-rat-c233="">Contact :</h5>
                                    <dl class="row mb-0">
                                        <dt class="col-2 text-muted"><i _ngcontent-rat-c233=""
                                                class="uil uil-envelope"></i>
                                        </dt>
                                        <dd class="col-10 text-muted"><a _ngcontent-rat-c233=""
                                                href="mailto:contact@example.com"
                                                class="text-muted">nisallcorp@gmail.com</a>
                                        </dd>
                                        <dt class="col-2 text-muted"><i-feather name="phone" class="fea icon-sm"
                                                _nghost-rat-c51="" ng-reflect-name="phone"><svg
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                    class="feather feather-phone">
                                                    <path
                                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                    </path>
                                                </svg></i-feather></dt>
                                        <dd class="col-10 text-muted"><a _ngcontent-rat-c233=""
                                                href="tel:+152534-468-854" class="text-muted">
                                                {{nisaDetails.phoneNumber}}</a>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-middle py-4">
                            <h5 _ngcontent-rat-c233="">Invoice Details :</h5>
                            <div class="row mb-0">
                                <div class="col-md-6 order-2 order-md-1">
                                    <dl class="row">
                                        <dt class="col-md-3 col-5 fw-normal">Invoice :</dt>
                                        <dd class="col-md-9 col-7 text-muted">{{registrationData?.orderID}}</dd>
                                        <dt class="col-md-3 col-5 fw-normal">Reg Id :</dt>
                                        <dd class="col-md-9 col-7 text-muted">{{registrationData?.registrationId}}</dd>
                                        <dt class="col-md-3 col-5 fw-normal">Name :</dt>
                                        <dd class="col-md-9 col-7 text-muted">{{registrationData.participant_name}}</dd>
                                        <!-- <dt class="col-md-3 col-5 fw-normal">Address :</dt>
                                        <dd class="col-md-9 col-7 text-muted">
                                            <p class="mb-0">1962 Pike Street,</p>
                                            <p class="mb-0">Diego, CA 92123</p>
                                        </dd> -->
                                        <dt class="col-md-3 col-5 fw-normal">Phone :</dt>
                                        <dd class="col-md-9 col-7 text-muted">
                                            {{formatPhoneNumber(registrationData.participant_phone)}}</dd>
                                    </dl>
                                </div>
                                <div class="col-md-6 order-md-2 order-1 mt-2 mt-sm-0">
                                    <dl class="row mb-0">
                                        <dt class="col-md-4 col-5 fw-normal">Date :</dt>
                                        <dd class="col-md-8 col-7 text-muted">{{registrationData.timestamp |
                                            date:'medium'}}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-table pb-4">
                            <div class="table-responsive bg-white shadow rounded">
                                <table class="table mb-0 table-center invoice-tb">
                                    <thead class="bg-light">
                                        <tr _ngcontent-rat-c233="">
                                            <th scope="col" class="border-bottom text-start">No.</th>
                                            <th scope="col" class="border-bottom text-start">Item
                                            </th>
                                            <th scope="col" class="border-bottom">Qty</th>
                                            <th scope="col" class="border-bottom">Rate</th>
                                            <th scope="col" class="border-bottom">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody _ngcontent-rat-c233="">
                                        <tr _ngcontent-rat-c233="">
                                            <th scope="row" class="text-start">1</th>
                                            <td class="text-start">{{registrationData.title}} -
                                                {{registrationData.month}}</td>
                                            <td _ngcontent-rat-c233="">1</td>
                                            <td _ngcontent-rat-c233="">{{registrationData.fee}}</td>
                                            <td _ngcontent-rat-c233="">{{registrationData.fee}}</td>
                                        </tr>
                                        <!-- <tr _ngcontent-rat-c233="">
                                            <th scope="row" class="text-start">2</th>
                                            <td class="text-start">Handy Guide</td>
                                            <td _ngcontent-rat-c233="">1</td>
                                            <td _ngcontent-rat-c233="">$ 3660</td>
                                            <td _ngcontent-rat-c233="">$ 3660</td>
                                        </tr>
                                        <tr _ngcontent-rat-c233="">
                                            <th scope="row" class="text-start">3</th>
                                            <td class="text-start">Premiere Product</td>
                                            <td _ngcontent-rat-c233="">3</td>
                                            <td _ngcontent-rat-c233="">$ 4580</td>
                                            <td _ngcontent-rat-c233="">$ 13740</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-5 ms-auto">
                                    <ul class="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                                        <li class="text-muted d-flex justify-content-between">
                                            Subtotal :<span _ngcontent-rat-c233="">{{registrationData.fee}}</span></li>
                                        <li class="text-muted d-flex justify-content-between">Taxes
                                            :<span _ngcontent-rat-c233=""> 0</span></li>
                                        <li class="d-flex justify-content-between">Total :<span
                                                _ngcontent-rat-c233="">{{registrationData.fee}}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="text-center">
            <a routerlink="/" ng-reflect-router-link="/" href="/" class="btn btn-soft-primary mt-3">Go To Home</a>
            <a routerlink="/" ng-reflect-router-link="/" href="/" class="btn btn-soft-primary mt-3"><i-feather
                    name="star" class="fea icon-sm icons"></i-feather>Print</a>
        </div> -->
        <div class="text-center mt-4">
            <div class="row">
                <div class="col">
                    <a href="/" class="btn btn-icon btn-lg btn-primary mt-2"><i-feather name="home"
                            class="icons"></i-feather></a>
                </div>
                <div class="col">
                    <a (click)="printDiv()" class="btn btn-icon btn-lg btn-primary mt-2"><i-feather name="printer"
                            class="icons" _nghost-rat-c51="" ng-reflect-name="facebook"></i-feather></a>
                </div>
            </div>

        </div>
    </div>
    <!-- <section class="section pb-0">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card border-0 text-center features feature-primary feature-clean">
                        <div class="icons text-center mx-auto"><i _ngcontent-rat-c228=""
                                class="uil uil-home d-block rounded h3 mb-0"></i></div>
                        <div class="content mt-4">
                            <h5 class="fw-bold">Home</h5>

                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card border-0 text-center features feature-primary feature-clean" (click)="printDiv()">
                        <div class="icons text-center mx-auto"><i _ngcontent-rat-c228=""
                                class="uil uil-print d-block rounded h3 mb-0"></i></div>
                        <div class="content mt-4">
                            <h5 class="fw-bold">Print</h5>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</section>