<section class="bg-half-170 border-bottom d-table w-100" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <div class="title-heading mt-4">

                    <h1 class="heading mb-3">
                        <ngx-typed-js [strings]="['Volleyball', 'Throwball', 'Badminton', 'Chess']" [shuffle]="true"
                            [typeSpeed]="80" [loop]="true">
                            Providing Platform For Sports <span class="element text-primary typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p class="para-desc text-muted">
                        Join us in building the next generation players & experiences.
                    </p>
                    <div class="mt-4">
                        <a [routerLink]="['/register']" class="btn btn-outline-primary rounded"><i
                                class="fa-solid fa-flag"></i>
                            Register</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="position-relative">
                    <img src="assets/images/nisa-group.png" class="rounded img-fluid mx-auto d-block" alt="" />
                    <div class="play-icon">
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
                            class="play-btn video-play-icon border-0">
                            <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
                        </a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <youtube-player [videoId]="'1oovZIZ2V7A'" height="450" width="780">
                                </youtube-player>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <hr>
        <div class="activities-container">
            <div class="activities-header">
                <h1>Upcoming Events</h1>
                <!-- Additional header content goes here, e.g., sales number -->
            </div>

            <ul class="events-list">
                <li class="event-item" *ngFor="let event of upcomingEvents">
                    <span class="event-date">{{ event.date | date:'mediumDate' }}</span>
                    <!-- <span class="event-time">{{ event.time }}</span> -->
                    <span class="status-indicator" [ngStyle]="{'background-color': event.status}"></span>
                    <span class="event-description">{{ event.title }}</span>
                    <a href="{{event.link}}" class="register-link">Click Me</a>
                    <!-- <a href="/register/{{event.id}}" class="register-link">Enroll</a> -->
                </li>
            </ul>
        </div>
    </div>
</section>