import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { RegistrationService } from '../services/registration.service';
declare let paypal;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  data: any;

  amount: number;
  orderID: any;
  clientId: string;

  showPaypalOption = false;

  constructor(private dataService: DataService, private router: Router, private service: RegistrationService) {
    this.data = this.dataService.getData();
    console.log(this.data);
  }

  ngOnInit(): void {
    this.clientId = 'ATFNMvx7UNWd6WplM-fGcu3-FD00EolksFwoNY67ROYmHNylxDZgeUA6vSoSWy4iLXweba9CEt_ww-o4';

    paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              currency_code: "USD",
              value: this.data.fee.match(/\d+\.\d+/)[0]
            }, description: this.data.title + ' - ' + this.data.month,
            payer: {
              email_address: this.data.participant_email,
              phone: {
                phone_number: {
                  national_number: this.data.participant_phone
                }
              }
            }
          }],
          disableFunding: ['paylater', 'card']
        });
      },
      onApprove: (data, actions) => {
        actions.order.capture().then(details => {
          this.orderID = data.orderID;
          this.data.orderID = this.orderID;
          this.data.payment_ref = data.id;

          let body = {
            event_id: this.data.event_id,
            participant_name: this.data.participant_name,
            participant_email: this.data.participant_email,
            participant_phone: this.data.participant_phone,
            payment_ref: this.orderID,
            payment_status: 'VERIFIED',
            timestamp: this.data.timestamp,
            skill_level: this.data.skill_level,
            time_slot_one: this.data.time_slot_one,
            time_slot_two: this.data.time_slot_two,
            title: this.data.title,
            fee: this.data.fee,
            month: this.data.month
          };


          this.service.postRegistration(body).subscribe(resp => {
            this.data.registrationId = resp.reg_id;
            this.dataService.setData(this.data);
            this.router.navigate(['/success']);
          }, err => {
            console.log('HTTP Error', err)
          });


        });
      },
      clientId: this.clientId
    }).render('#paypal-button-container');

    document.getElementById('paypal-button-container').hidden = true;
    document.getElementById('cash-button-container').hidden = true;

  }


  register() {
    let body = {
      event_id: this.data.event_id,
      participant_name: this.data.participant_name,
      participant_email: this.data.participant_email,
      participant_phone: this.data.participant_phone,
      payment_ref: 'CASH',
      payment_status: 'NOT VERIFIED',
      timestamp: this.data.timestamp,
      skill_level: this.data.skill_level,
      time_slot_one: this.data.time_slot_one,
      time_slot_two: this.data.time_slot_two,
      title: this.data.title,
      fee: this.data.fee,
      month: this.data.month
    };


    this.service.postRegistration(body).subscribe(resp => {
      this.data.registrationId = resp.reg_id;
      this.dataService.setData(this.data);
      this.router.navigate(['/success']);
    }, err => {
      console.log('HTTP Error', err)
    });
  }

  paymentOptionChanged(evt) {
    if (evt?.target?.id === 'paypalChkBx') {
      document.getElementById('paypal-button-container').hidden = false;
      document.getElementById('cash-button-container').hidden = true;
    } else {
      document.getElementById('paypal-button-container').hidden = true;
      document.getElementById('cash-button-container').hidden = false;
    }
  }

}
