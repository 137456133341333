import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  private local_url = 'http://localhost:8080/';

  private base_url = 'http://nisabeckend-env.eba-z6s3yepf.us-east-1.elasticbeanstalk.com/';

  private url = this.base_url;

  @Input() service_class: string | undefined;

  @Input() servicesData: Array<{
    icon: string;
    title: number,
    description: string
  }>;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  getEventTypes() {
    return this.httpClient.get(this.url + '/eventtypes', { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }) });
  }

  getAllEvents() {
    return this.httpClient.get(this.url + 'events', { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }) });
  }
  getEvents(eventType: string) {
    const params = new HttpParams().append('eventType', eventType);
    return this.httpClient.get(this.url + 'events', { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }), params: params });
  }

  getTotalregistrations(eventId: string) {
    const params = new HttpParams().append('id', eventId);
    return this.httpClient.post(this.url + '/events/registrations', eventId, { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }), params: params });

  }

  getSkills() {
    return this.httpClient.get(this.url + 'skills', { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }) });
  }

}
