import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RegistrationService } from '../services/registration.service';
import { loadScript, OrderResponseBody } from "@paypal/paypal-js";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationExtras, Router } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  paypal;

  developmentForm!: UntypedFormGroup;
  submitted = false;
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  showCards: boolean = true;
  displayPayPalButtons: boolean = false;

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  registrationId: string = "";
  trasactionId: string = "";
  trasactionStatus: string = "";
  trasactionDate: string = "";

  selectedData: any;
  sportsData: any;
  sportsList = [];

  // sportsData = [
  //   {
  //     sport: 'Badminton',
  //     eventId: '123456',
  //     title: "Open To All",
  //     price: 40,
  //     list: ["Beginner", "Intermediate", "Advanced"],
  //     btn: "Select",
  //     month: "02/23",
  //     frequency: "Monthly"
  //   },
  //   {
  //     sport: 'Badminton',
  //     title: "Women's Badminton",
  //     price: 25,
  //     list: ["Beginner", "Intermediate", "Advanced"],
  //     btn: "Select",
  //     month: "02/23",
  //     frequency: "Monthly"
  //   }
  // ];

  /**
   * Partners slider
   */
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };
  skills: any;
  slots: any;

  constructor(private service: RegistrationService, private formBuilder: UntypedFormBuilder, private modalService: NgbModal, private router: Router, private dataService: DataService) {

  }

  ngOnInit(): void {

    this.developmentForm = this.formBuilder.group({
      sport: ['', Validators.required],
      eventName: ['', Validators.required],
      month: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      skillLevel: ['', Validators.required],
      fee: ['', Validators.required],
      timePrefOne: ['', Validators.required],
      timePrefTwo: ['', Validators.required],

    });

    this.service.getSkills().subscribe(data => {
      this.skills = data;
    });

    this.service.getActiveEvents().subscribe(data => {
      this.sportsData = data;
      this.sportsData?.forEach(sport => {

        if (!this.sportsList.includes(sport?.sport)) {
          this.sportsList.push(sport?.sport);
        }

        let instList = sport?.instructions?.split(',');
        let instItems: string[] = [];
        instList.forEach(data => {
          if (data) {
            instItems.push(data.trim());
          }
        });
        sport.list = instItems;

        this.service.getTotalregistrations(sport.event_id).subscribe(data => {
          sport.totalRegs = data;
        });
      });
    });

    //this.loadPayPal();
  }

  loadPayPal(price: any) {
    loadScript({ "client-id": "AaXxnDmp0p79tXclGOtHs_QEjocEaYjqZyKI6EyiWNJ_Jq91u4NtjJHwQWmlMdFUushLOFtMaX7N-6Ae" })
      .then((paypal) => {
        paypal
          .Buttons({
            createOrder: function (data, actions) {
              // Set up the transaction
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: price
                  }
                }]
              });
            },
            onApprove: function (data, actions) {
              // This function captures the funds from the transaction.
              return actions.order.capture().then(function (details) {
                //this.trasactionDetails = details;
                //console.log(details);

              });
            }
          })
          .render("#paypal-buttons")
          .catch((error) => {
            console.error("failed to render the PayPal Buttons", error);
          });
      })
      .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
      });
  }

  // convenience getter for easy access to form fields
  get form() { return this.developmentForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.developmentForm.invalid) {
      return;
    } else {
      // const nameEle = document.getElementById('name') as HTMLInputElement | null;
      // const emailEle = document.getElementById('email') as HTMLInputElement | null;
      // const phoneEle = document.getElementById('phone') as HTMLInputElement | null;
      // const skillEle = document.getElementById('skill') as HTMLInputElement | null;

      // nameEle?.setAttribute('disabled', '');
      // emailEle?.setAttribute('disabled', '');
      // phoneEle?.setAttribute('disabled', '');
      // skillEle?.setAttribute('disabled', '');

      //this.displayPayPalButtons = true;
      //this.loadPayPal(this.selectedData?.price);

      let body = {
        event_id: this.selectedData.event_id,
        participant_name: this.developmentForm?.value?.name,
        participant_email: this.developmentForm?.value?.email,
        participant_phone: this.developmentForm?.value?.phone,
        //payment_ref: this.developmentForm?.value?.transRef,
        payment_status: 'NOT VERIFIED',
        timestamp: new Date(),
        skill_level: this.developmentForm?.value?.skillLevel,
        time_slot_one: this.developmentForm?.value?.timePrefOne,
        time_slot_two: this.developmentForm?.value?.timePrefTwo,
        title: this.selectedData.title,
        fee: this.developmentForm?.value?.fee,
        month: this.developmentForm?.value?.month
      };

      this.dataService.setData(body);

      this.router.navigate(['/payment']);
    }
  }

  showRegistration(data) {
    this.selectedData = data;

    let slotList = data?.slots?.split(',');
    let slotItems: string[] = [];
    slotList.forEach(data => {
      if (data) {
        slotItems.push(data.trim());
      }
    });
    this.slots = slotItems;

    if (this.slots.length > 0) {

      if (this.selectedData.sport === 'Badminton') {
        this.developmentForm = this.formBuilder.group({
          name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)]],
          sport: [data.sport, Validators.required],
          eventName: [data.title, Validators.required],
          month: [data.month, Validators.required],
          skillLevel: ['', Validators.required],
          fee: [this.formatter.format(data.price), Validators.required],
          eventFreq: [data.frequency, Validators.required],
          faqAgreementChkbx: ['', Validators.required],
          birdieAgreementChkbx: ['', Validators.required],
          waiverAgreementChkbx: ['', Validators.required],
          //transRef: ['', Validators.required],
          timePrefOne: ['', Validators.required],
          timePrefTwo: ['', Validators.required]
        });
      } else {
        this.developmentForm = this.formBuilder.group({
          name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)]],
          sport: [data.sport, Validators.required],
          eventName: [data.title, Validators.required],
          month: [data.month, Validators.required],
          skillLevel: ['', Validators.required],
          fee: [this.formatter.format(data.price), Validators.required],
          eventFreq: [data.frequency, Validators.required],
          waiverAgreementChkbx: ['', Validators.required],
          //transRef: ['', Validators.required],
          timePrefOne: ['', Validators.required],
          timePrefTwo: ['', Validators.required]
        });
      }

    } else {

      if (this.selectedData.sport === 'Badminton') {
        this.developmentForm = this.formBuilder.group({
          name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)]],
          sport: [data.sport, Validators.required],
          eventName: [data.title, Validators.required],
          month: [data.month, Validators.required],
          skillLevel: ['', Validators.required],
          fee: [this.formatter.format(data.price), Validators.required],
          eventFreq: [data.frequency, Validators.required],
          faqAgreementChkbx: ['', Validators.required],
          birdieAgreementChkbx: ['', Validators.required],
          waiverAgreementChkbx: ['', Validators.required]
        });
      } else {
        this.developmentForm = this.formBuilder.group({
          name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)]],
          sport: [data.sport, Validators.required],
          eventName: [data.title, Validators.required],
          month: [data.month, Validators.required],
          skillLevel: ['', Validators.required],
          fee: [this.formatter.format(data.price), Validators.required],
          eventFreq: [data.frequency, Validators.required],
          waiverAgreementChkbx: ['', Validators.required]
        });
      }

    }

    this.showCards = false;
    //this.loadPayPal(data.price);
  }

  Subscribeopen(content) {
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
  }

  refresh(): void {
    window.location.reload();
  }

  getMonthInString(month: string) {
    let m = Number(month.split('/')[0]);
    switch (m) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
    }

  }

  msg(data) {
    console.log(data);
  }

  getSports(sport) {
    return this.sportsData.filter((item) => item.sport === sport);
  }


}
