<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="/index" class="btn btn-icon btn-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<!-- Hero Start -->
<section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-8">
                <div class="card login-page shadow rounded border-0">
                    <div class="card-body">
                        <h4 class="card-title text-center">Login</h4>
                        <form class="login-form mt-4" [formGroup]="authForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">User Name<span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="Username"
                                                name="user" required="" formControlName="user"
                                                [ngClass]="{ 'is-invalid': submitted && form['user'].errors }"
                                                (ngModelChange)="onChange()">
                                            <div *ngIf="submitted && form['user'].errors" class="invalid-feedback">
                                                <div *ngIf="form['user'].errors">Please Enter User Name.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Password <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                            <input type="password" class="form-control ps-5" placeholder="Password"
                                                required="" formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && form['password'].errors }"
                                                (ngModelChange)="onChange()">
                                            <div *ngIf="submitted && form['password'].errors" class="invalid-feedback">
                                                <div *ngIf="form['password'].errors">Please Enter Password.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-lg-12">
                                    <div class="d-flex justify-content-between">
                                        <div class="mb-3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault">
                                                <label class="form-check-label" for="flexCheckDefault">Remember
                                                    me</label>
                                            </div>
                                        </div>
                                        <p class="forgot-pass mb-0"><a routerLink="/auth-re-password-three"
                                                class="text-dark fw-bold">Forgot
                                                password ?</a></p>
                                    </div>
                                </div>
                                <div *ngIf="submitted && !form['user'].errors && !form['password'].errors && !isLoggedIn"
                                    role="alert" class="alert alert-danger"> Incorrect User Name or
                                    Password !</div>
                                <div class="col-lg-12 mb-0">
                                    <div class="d-grid">
                                        <button class="btn btn-primary" (click)="onSubmit()">Sign in</button>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-12 mt-4 text-center">
                    <h6>Or Login With</h6>
                    <div class="row">
                      <div class="col-6 mt-3">
                        <div class="d-grid">
                          <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-facebook text-primary"></i>
                            Facebook</a>
                        </div>
                      </div>
  
                      <div class="col-6 mt-3">
                        <div class="d-grid">
                          <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-google text-danger"></i>
                            Google</a>
                        </div>
                      </div>
                    </div>
                  </div> -->

                                <!-- <div class="col-12 text-center">
                    <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a
                        routerLink="/auth-signup-three" class="text-dark fw-bold">Sign Up</a></p>
                  </div> -->
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                </div>
                <!---->
            </div>
            <!--end col-->
        </div>

        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->