import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private local_url = 'https://localhost';

  //private base_url = 'http://nisabeckend-env.eba-z6s3yepf.us-east-1.elasticbeanstalk.com';

  private base_url = 'https://rx850lmvs8.execute-api.us-east-1.amazonaws.com/dev';

  private url = this.base_url;

  headers = new HttpHeaders({
    'Origin': 'http://localhost:4200' // Replace with your frontend URL
  });

  constructor(private httpClient: HttpClient) { }

  setGroupFilter$ = new Subject<any>();
  getGroupFilter = this.setGroupFilter$.asObservable();

  getEventTypes() {
    return this.httpClient.get(this.url + '/eventtypes', { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }) });
  }

  getActiveEvents(): Observable<any> {
    return this.httpClient.get(this.url + '/events', { responseType: 'json' }).pipe(map(result => {
      return result;
    }));
  }

  getAllEvents(): Observable<any> {
    return this.httpClient.get(this.url + '/allevents', { responseType: 'json' }).pipe(map(result => {
      return result;
    }));
  }

  getEvents(eventType: string) {
    const params = new HttpParams().append('eventType', eventType);
    return this.httpClient.get(this.url + '/events', { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }), params: params });
  }

  getTotalregistrations(eventId: string) {
    // const params = new HttpParams().append('id', eventId);
    // return this.httpClient.post(this.url + '/events/registrations', eventId, { responseType: 'json', headers: new HttpHeaders({ timeout: '300000' }), params: params }).pipe(map(result => {
    //   return result;
    // }));
    return this.httpClient.get(this.url + '/totalregs?id=' + eventId, { responseType: 'json' });

  }

  getSkills() {
    //return this.httpClient.get(this.url + '/skills', { responseType: 'json', headers: new HttpHeaders({ "content-type": "application/json" }) });
    return this.httpClient.get(this.url + '/skills');
  }

  postRegistration(body: any): any {
    const headers = { timeout: '300000' };
    return this.httpClient.post<any>(this.url + '/addregistration', body);
  }

  getRegistrations(lastEvaluatedKey, itemsPerPage): Observable<any> {
    let body;
    let params = new HttpParams();

    if (lastEvaluatedKey) {
      body = { lastEvaluatedKey: lastEvaluatedKey, itemsPerPage: itemsPerPage };

    } else {
      body = { itemsPerPage: itemsPerPage };
    }
    params = params.set('data', JSON.stringify(body));
    return this.httpClient.get(this.url + '/registrations', { params, responseType: 'json' });
  }

  getAllRegistrations(): Observable<any> {
    return this.httpClient.get(this.url + '/registrations', { responseType: 'json' });
  }

  getEventsById(eventId: string): Observable<any> {
    return this.httpClient.get(this.url + '/event?id=' + eventId, { responseType: 'json' });
  }

  getUpcomingEvents(): Observable<any> {
    return this.httpClient.get(this.url + '/upcomingevents', { responseType: 'json' });
  }
}
