<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-lg-8">
                <div class="card rounded shadow p-4 border-0">
                    <h5 class="mb-3">Billing Details :</h5>
                    <form novalidate="" class="needs-validation ng-untouched ng-pristine ng-valid">
                        <div class="row g-3">
                            <div class="col-sm-6"><label class="form-label">Name</label><input name="name"
                                    id="firstname" type="text" placeholder="Name " disabled class="form-control"
                                    [value]="data.participant_name">
                                <div class="invalid-feedback"> Valid Name is required.
                                </div>
                            </div>
                            <div class="col-sm-6"><label for="lastName" class="form-label">Phone</label><input
                                    type="text" id="lastName" placeholder="Phone" value="" required="" disabled
                                    class="form-control" [value]="data.participant_phone">
                                <div class="invalid-feedback"> Valid Phone is required.
                                </div>
                            </div>
                            <div class="col-12"><label for="email" class="form-label">Email</label><input type="email"
                                    id="email" placeholder="you@example.com" class="form-control" disabled
                                    [value]="data.participant_email">
                                <div class="invalid-feedback"> Please enter a valid email address. </div>
                            </div>
                        </div>

                        <h4 class="mb-3 mt-4 pt-4 border-top">Payment</h4>
                        <div class="my-3">
                            <div class="form-check">
                                <input id="cashChkBx" name="paymentMethod" type="radio" required=""
                                    class="form-check-input" (change)="paymentOptionChanged($event)">
                                <label for="cashChkBx" class="form-check-label">Cash</label>
                            </div>
                            <div class="form-check">
                                <input id="paypalChkBx" name="paymentMethod" type="radio" required=""
                                    class="form-check-input" (change)="paymentOptionChanged($event)">
                                <label for="paypalChkBx" class="form-check-label">Online [CREDIT / DEBIT /
                                    PAYPAL]</label>
                            </div>
                        </div>
                        <!-- <button id="payPalButton" type="submit" class="w-100 btn btn-primary">Continue to
                            payment</button> -->
                        <div id="paypal-button-container"></div>
                        <div id="cash-button-container" class="text-center">
                            <a href="javascript:void(0)" class="btn btn-primary" (click)="register()"> Submit </a>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-5 col-lg-4 order-md-last">
                <div class="card rounded shadow p-4 border-0">
                    <div class="d-flex justify-content-between align-items-center mb-3"><span class="h5 mb-0">Your
                            cart</span><span class="badge bg-primary rounded-pill">1</span></div>
                    <ul class="list-group mb-3 border">
                        <li class="d-flex justify-content-between lh-sm p-3 border-bottom">
                            <div>
                                <h6 class="my-0">{{data.title}}</h6><small class="text-muted">{{data.month | date:
                                    'MMMM'}}</small>
                            </div><span class="text-muted">{{data.fee}}</span>
                        </li>
                        <li class="d-flex justify-content-between p-3"><span>Total
                                (USD)</span><strong>{{data.fee}}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<app-success-page [registrationData]="data"></app-success-page>