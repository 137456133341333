import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { RegistrationService } from '../services/registration.service';

export interface RegistrationEle {
  registrationId: string;
  paymentRef: string;
  regNo: string;
  eventId: string;
  eventTitle: string;
  eventName: string
  eventMonth: string;
  eventFee: number;
  name: string;
  email: string;
  phone: string;
  paymentStatus: string;
  timestamp: string;
  color: string;
  timeSlotOne: string;
  timeSlotTwo: string;
  skillLevel: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnChanges, AfterViewInit {
  Menuoption = 'center';
  /**
   * Header button list show
   */
  //shopPages = true;
  //Menuoption = 'shop'
  //footerVariant = 'footer-two';
  loginStatus = false;
  registrationCount = 0;

  registrations;
  processedRegistrations: RegistrationEle[] = [];
  filteredRegistrations: RegistrationEle[] = [];
  events: any[] = [];

  eventName: string;

  @Input() groupFiltersInput: Object;
  @Input() searchByKeyword: string;

  @Output() autoSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();

  pipe = new DatePipe('en-US');

  registrationForm: FormGroup;
  registrationUpdateForm: FormGroup;

  processingComplete = false;

  selectedRegistration: RegistrationEle;

  displayedColumns = ['regNo', 'event', 'name', 'paymentStatus', 'timestamp', 'action'];
  dataSource: MatTableDataSource<RegistrationEle>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  lastEvaluatedKey;
  itemsPerPage = 50;
  currentPage = 0;

  constructor(private router: Router, private service: RegistrationService, private fb: FormBuilder, private snackBar: MatSnackBar) {
    this.dataSource = new MatTableDataSource<RegistrationEle>();
  }


  ngOnInit(): void {
    //this.buildForm();

    // if (!(sessionStorage.getItem('login-status') === 'loggedIn')) {
    //   this.router.navigate(['/login']);
    // }

    this.service.getAllEvents().subscribe(events => {
      this.events = events;
    });

    this.loadRegistrations();

  }

  loadRegistrations() {
    this.service.getAllRegistrations()
      .subscribe((res: any) => {
        //this.lastEvaluatedKey = res.lastEvaluatedKey;
        this.registrations = res;
      },
        (err) => { },
        () => {
          this.processRegistrations();
        })
  }
  viewRegistration(reg: RegistrationEle) {
    const paymentStatusCtrlEle = document.getElementById("paymentStatusCtrl") as HTMLSelectElement;

    if (paymentStatusCtrlEle) {
      paymentStatusCtrlEle.selectedIndex = 0;
    }

    this.selectedRegistration = reg;
  }


  ngOnChanges(changes: SimpleChanges) {
  }

  private processRegistrations() {
    this.processedRegistrations = [];
    this.registrations?.items?.forEach((registration) => {
      let eventTitle = '';
      let eventMonth = '';
      let eventFee = 0;

      const event = this.events.find(obj => obj.event_id === registration.event_id);

      eventTitle = event?.title;
      eventMonth = event?.month;
      eventFee = event?.price;

      let reg = {
        registrationId: registration.reg_id,
        paymentRef: registration.payment_ref,
        regNo: 'x' + registration.reg_id.slice(-4),
        eventId: registration.event_id,
        eventTitle: eventTitle,
        eventName: eventTitle + ' ' + eventMonth,
        eventMonth: eventMonth,
        eventFee: eventFee,
        name: registration.participant_name,
        email: registration.participant_email,
        phone: registration.participant_phone,
        paymentStatus: registration.payment_status,
        timestamp: this.pipe.transform(registration.timestamp, 'short'),
        color: (registration.payment_status === 'VERIFIED' ? 'green' : 'red'),
        timeSlotOne: registration.time_slot_one,
        timeSlotTwo: registration.time_slot_two,
        skillLevel: registration.skill_level
      };

      this.processedRegistrations.push(reg);

      if (this.processedRegistrations.length === this.registrations.items.length) {
        //this.dataSource.data = this.dataSource.data.concat(this.processedRegistrations);
        this.dataSource = new MatTableDataSource<RegistrationEle>(this.processedRegistrations);
        //this.lastEvaluatedKey = data.LastEvaluatedKey;

        this.dataSource.sort = this.sort;
        this.paginator.pageSize = this.itemsPerPage;
        //this.paginator.length = this.dataSource.data.length;
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  logout() {
    sessionStorage.removeItem('login-status');
    this.router.navigate(['/login']);
  }

  buildForm(): void {
    this.registrationForm = this.fb.group({
      eventName: new FormControl(''),
    });

    this.registrationUpdateForm = this.fb.group({
      paymentRefCtrl: new FormControl(''),
      paymentStatusCtrl: new FormControl('')
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngAfterViewInit() {
    // if (this.dataSource) {
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // }
  }

  refreshData() {
    this.dataSource.sort = this.sort;
  }

  saveChanges() {

    const paymentRefCtrlEle = document.getElementById("paymentRefCtrl") as HTMLInputElement;
    const paymentStatusCtrlEle = document.getElementById("paymentStatusCtrl") as HTMLSelectElement;

    let body = {
      reg_id: this.selectedRegistration.registrationId,
      event_id: this.selectedRegistration.eventId,
      participant_name: this.selectedRegistration.name,
      participant_email: this.selectedRegistration.email,
      participant_phone: this.selectedRegistration.phone,
      payment_ref: paymentRefCtrlEle?.value,
      payment_status: paymentStatusCtrlEle?.options[paymentStatusCtrlEle?.selectedIndex]?.value,
      timestamp: this.selectedRegistration.timestamp,
      skill_level: this.selectedRegistration.skillLevel,
      time_slot_one: this.selectedRegistration.timeSlotOne,
      time_slot_two: this.selectedRegistration.timeSlotTwo
    };

    console.log(body);

    this.service.postRegistration(body).subscribe(resp => {
      this.snackBar.open('Success!', '', {
        duration: 3000,
        panelClass: ['middle-snackbar', 'success-snackbar']
      });
      this.refreshTable();
      console.log(resp);
    }, err => {
      console.log('HTTP Error', err)
    });
  }

  refreshTable() {
    this.loadRegistrations();
    this.dataSource.data = [...this.dataSource.data];
  }

  onPageChange(event) {
    //this.currentPage = event.pageIndex;
    //this.itemsPerPage = event.pageSize;
    //this.refreshTable();
  }

  goBackward() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.paginator.previousPage();
      this.refreshTable();
    }
  }

  goForward() {
    if (this.lastEvaluatedKey) {
      this.paginator.nextPage();
      this.refreshTable();
    }
  }

}