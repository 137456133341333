import { Component, ElementRef, Input, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})

export class SuccessComponent {
  @ViewChild('printableContent', { static: false }) printableContent: ElementRef;
  @Input() registrationData: any;
  trasactionStatus = 'COMPLETED';
  Menuoption = 'center';
  Settingicon = true;
  nisaDetails: any;

  constructor(private dataService: DataService, private router: Router) {
    console.log(dataService.getData())
    this.registrationData = dataService.getData();
    if (this.registrationData === null || this.registrationData === undefined) {
      this.router.navigate(['/']);
    }
    // this.registrationData = {
    //   "event_id": "10101010",
    //   "participant_name": "Sooraj Mannarath",
    //   "participant_email": "srjrivals@gmail.com",
    //   "participant_phone": "8608395566",
    //   "payment_status": "NOT VERIFIED",
    //   "timestamp": "2023-02-26T00:53:37.077Z",
    //   "skill_level": "Advanced",
    //   "time_slot_one": "Sat 7:00 AM - 8:30 AM",
    //   "time_slot_two": "Sat 7:00 AM - 8:30 AM",
    //   "title": "Women Only Session",
    //   "fee": "$25.00",
    //   "month": "03/01/2023",
    //   "orderID": "9P2506235M1213159",
    //   "registrationId": "1944e6df-7cfd-4ffc-bd35-69b01a5e3565"
    // };

    this.nisaDetails = {
      'phoneNumber': this.formatPhoneNumber('8605028937')
    }
  }

  formatPhoneNumber(phoneNumber: string): string {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }

  printDiv() {
    let printContents = this.printableContent.nativeElement.innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

}

