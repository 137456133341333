<section class="section pb-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Registrations are open now</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Book your <span
                            class="text-primary fw-bold">slot</span>
                        before it runs out.</p>
                </div>
            </div>
            <!--end col-->
        </div>
    </div>

</section>
<section *ngIf="!(submitted && trasactionStatus === 'COMPLETED')">
    <div class="row align-items-center" *ngIf="showCards">
        <div class="col-12 mt-4">
            <div class="text-center">
                <ul ngbNav #nav="ngbNav"
                    class="nav-pills bg-white rounded-lg justify-content-center d-inline-block shadow py-1 px-2 mb-0">
                    <li ngbNavItem class="d-inline-block">
                        <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Monthly" role="tab"
                            aria-controls="Month" aria-selected="true">All Sports</a>
                        <ng-template ngbNavContent>
                            <div [ngClass]="{'row': true, 'reg-cards' : true, 'justify-content-center': true}">
                                <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 text-sm-start ng-star-inserted"
                                    *ngFor="let data of sportsData"
                                    [ngClass]="{'noHover': data.totalRegs >= data.limit ? true : false }">
                                    <div class="card pricing pricing-primary business-rate shadow border-0 rounded"
                                        [ngClass]="{'bg-light': !data.warning}">
                                        <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                                                class="text-center d-block shadow small h6">{{data.warning}}</span>
                                        </div>
                                        <div class="card-body ">
                                            <h6 class="title fw-bold text-uppercase mb-2"
                                                [ngClass]="{'text-secondary': data.totalRegs >= data.limit, 'text-primary': data.totalRegs < data.limit }">
                                                {{data.title}} <span class="badge rounded-pill bg-danger"
                                                    *ngIf="data.totalRegs >= data.limit"> &emsp;Full
                                                </span>
                                            </h6>

                                            <span class="badge rounded-pill mb-2"
                                                [ngClass]="{'bg-secondary': data.totalRegs >= data.limit, 'bg-primary': data.totalRegs < data.limit }">{{getMonthInString(data.month)}}</span>
                                            <br>
                                            <span *ngIf="data.timing" class="badge bg-soft-primary mb-2">
                                                {{data.timing}} </span>
                                            <div class="d-flex ">
                                                <span class="h4 mb-0 mt-2">$</span>
                                                <span class="price h1 mb-0">{{data.price}}</span>
                                                <span class="h4 align-self-end mb-1">/mo</span>
                                            </div>
                                            <ul class="list-unstyled mb-0 ps-0" *ngFor="let item of data.list">
                                                <li *ngIf="item" class="h6 text-muted mb-0"><span
                                                        class="text-primary h5 me-2"><i
                                                            class="uil uil-crosshairs align-middle"></i></span>{{item}}
                                                </li>
                                            </ul>
                                            <a class="btn mt-4" style="text-align: center;"
                                                [ngClass]="{'btn-secondary': data.totalRegs >= data.limit, 'btn-primary': data.totalRegs < data.limit }"
                                                (click)="showRegistration(data)">{{data.btn}}</a>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="d-inline-block" *ngFor="let sport of sportsList">
                        <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Monthly" role="tab"
                            aria-controls="Month" aria-selected="true">{{sport}}</a>
                        <ng-template ngbNavContent>
                            <div [ngClass]="{'row': true, 'reg-cards' : true, 'justify-content-center': true}">
                                <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 text-sm-start ng-star-inserted"
                                    *ngFor="let data of getSports(sport)"
                                    [ngClass]="{'noHover': data.totalRegs >= data.limit ? true : false }">
                                    <div class="card pricing pricing-primary business-rate shadow border-0 rounded"
                                        [ngClass]="{'bg-light': !data.warning}">
                                        <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                                                class="text-center d-block shadow small h6">{{data.warning}}</span>
                                        </div>
                                        <div class="card-body ">
                                            <h6 class="title fw-bold text-uppercase mb-2"
                                                [ngClass]="{'text-secondary': data.totalRegs >= data.limit, 'text-primary': data.totalRegs < data.limit }">
                                                {{data.title}} <span class="badge rounded-pill bg-danger"
                                                    *ngIf="data.totalRegs >= data.limit"> &emsp;Full
                                                </span>
                                            </h6>

                                            <span class="badge rounded-pill mb-2"
                                                [ngClass]="{'bg-secondary': data.totalRegs >= data.limit, 'bg-primary': data.totalRegs < data.limit }">{{getMonthInString(data.month)}}</span>
                                            <br>
                                            <span *ngIf="data.timing" class="badge bg-soft-primary mb-2">
                                                {{data.timing}} </span>
                                            <div class="d-flex ">
                                                <span class="h4 mb-0 mt-2">$</span>
                                                <span class="price h1 mb-0">{{data.price}}</span>
                                                <span class="h4 align-self-end mb-1">/mo</span>
                                            </div>
                                            <ul class="list-unstyled mb-0 ps-0" *ngFor="let item of data.list">
                                                <li *ngIf="item" class="h6 text-muted mb-0"><span
                                                        class="text-primary h5 me-2"><i
                                                            class="uil uil-crosshairs align-middle"></i></span>{{item}}
                                                </li>
                                            </ul>
                                            <a class="btn mt-4" style="text-align: center;"
                                                [ngClass]="{'btn-secondary': data.totalRegs >= data.limit, 'btn-primary': data.totalRegs < data.limit }"
                                                (click)="showRegistration(data)">{{data.btn}}</a>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                        </ng-template>
                    </li>

                    <!-- <li ngbNavItem class="d-inline-block">
                        <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Yearly" role="tab"
                            aria-controls="Month" aria-selected="true">Badminton</a>
                        <ng-template ngbNavContent>
                            <div [ngClass]="{'row': true, 'reg-cards' : true, 'justify-content-center': true}">
                                <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 text-sm-start ng-star-inserted"
                                    *ngFor="let data of sportsData"
                                    [ngClass]="{'noHover': data.totalRegs >= data.limit ? true : false }">
                                    <div class="card pricing pricing-primary business-rate shadow border-0 rounded"
                                        [ngClass]="{'bg-light': !data.warning}">
                                        <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
                                                class="text-center d-block shadow small h6">{{data.warning}}</span>
                                        </div>
                                        <div class="card-body ">
                                            <h6 class="title fw-bold text-uppercase mb-2"
                                                [ngClass]="{'text-secondary': data.totalRegs >= data.limit, 'text-primary': data.totalRegs < data.limit }">
                                                {{data.title}} <span class="badge rounded-pill bg-danger"
                                                    *ngIf="data.totalRegs >= data.limit"> &emsp;Full
                                                </span>
                                            </h6>

                                            <span class="badge rounded-pill mb-2"
                                                [ngClass]="{'bg-secondary': data.totalRegs >= data.limit, 'bg-primary': data.totalRegs < data.limit }">{{getMonthInString(data.month)}}</span>
                                            <br>
                                            <span *ngIf="data.timing" class="badge bg-soft-primary mb-2">
                                                {{data.timing}} </span>
                                            <div class="d-flex ">
                                                <span class="h4 mb-0 mt-2">$</span>
                                                <span class="price h1 mb-0">{{data.price}}</span>
                                                <span class="h4 align-self-end mb-1">/mo</span>
                                            </div>
                                            <ul class="list-unstyled mb-0 ps-0" *ngFor="let item of data.list">
                                                <li *ngIf="item" class="h6 text-muted mb-0"><span
                                                        class="text-primary h5 me-2"><i
                                                            class="uil uil-crosshairs align-middle"></i></span>{{item}}
                                                </li>
                                            </ul>
                                            <a class="btn mt-4" style="text-align: center;"
                                                [ngClass]="{'btn-secondary': data.totalRegs >= data.limit, 'btn-primary': data.totalRegs < data.limit }"
                                                (click)="showRegistration(data)">{{data.btn}}</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </ng-template>
                    </li> -->
                </ul>
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
        <!--end col-->
    </div>
</section>
<section class="registration-section" *ngIf="!showCards">

    <div class="container ">
        <div class="">
            <div class="card rounded border-0 shadow ms-lg-5">
                <div class="card-body">
                    <div style="text-align: center;">
                        <p class="text-muted para-desc mx-auto mb-0">Resgistration for <span
                                class="text-primary fw-bold">{{selectedData.title}}</span> </p>
                    </div>
                    <div class="content mt-4 pt-2">
                        <form [formGroup]="developmentForm" (ngSubmit)="onSubmit()">
                            <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Event Type : <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="play" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="" name="sport"
                                                required="" formControlName="sport"
                                                [ngClass]="{ 'is-invalid': submitted && form['sport'].errors }"
                                                readonly>
                                            <div *ngIf="submitted && form['sport'].errors" class="invalid-feedback">
                                                <div *ngIf="form['sport'].errors">Event Type Is Mandatory.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Event Name : <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="bookmark" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="" name="eventName"
                                                required="" formControlName="eventName"
                                                [ngClass]="{ 'is-invalid': submitted && form['eventName'].errors }"
                                                readonly>
                                            <div *ngIf="submitted && form['eventName'].errors" class="invalid-feedback">
                                                <div *ngIf="form['eventName'].errors">Event Name Is Mandatory.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Event Frequency : <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="repeat" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="" name="eventFreq"
                                                required="" formControlName="eventFreq"
                                                [ngClass]="{ 'is-invalid': submitted && form['eventFreq'].errors }"
                                                readonly>
                                            <div *ngIf="submitted && form['eventFreq'].errors" class="invalid-feedback">
                                                <div *ngIf="form['eventFreq'].errors">Event Frequency Is Mandatory.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Month : <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="calendar" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="" name="month"
                                                required="" formControlName="month"
                                                [ngClass]="{ 'is-invalid': submitted && form['month'].errors }"
                                                readonly>
                                            <div *ngIf="submitted && form['month'].errors" class="invalid-feedback">
                                                <div *ngIf="form['month'].errors">Month Is Mandatory.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="mt-3 mb-3" />
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Name : <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                            <input id="name" type="text" class="form-control ps-5" placeholder="Name"
                                                name="name" required="" formControlName="name"
                                                [ngClass]="{ 'is-invalid': submitted && form['name'].errors }">
                                            <div *ngIf="submitted && form['name'].errors" class="invalid-feedback">
                                                <div *ngIf="form['name'].errors">Please Enter Name.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Email : <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input id="email" type="email" class="form-control ps-5" placeholder="Email"
                                                name="email" required="" formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && form['email'].errors }">
                                            <div *ngIf="submitted && form['email'].errors" class="invalid-feedback">
                                                <div *ngIf="form['email'].errors">Please Enter A Valid Email.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Phone : <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                            <input id="phone" type="phone" class="form-control ps-5" placeholder="Phone"
                                                required="" formControlName="phone"
                                                [ngClass]="{ 'is-invalid': submitted && form['phone'].errors }">
                                            <div *ngIf="submitted && form['phone'].errors" class="invalid-feedback">
                                                <div *ngIf="form['phone'].errors">Please Enter A Valid 10 Digit Phone.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Skill Level : <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="zap" class="fea icon-sm icons"></i-feather>
                                            <select class="form-control ps-5" aria-label="Default select example"
                                                formControlName="skillLevel" name="skillLevel" id="skill"
                                                [ngClass]="{ 'is-invalid': submitted && form['skillLevel'].errors }">
                                                <option></option>
                                                <option *ngFor="let skill of skills" [value]="skill.skill_name">
                                                    {{skill.skill_name}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && form['skillLevel'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form['skillLevel'].errors">Please Select Skill.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <hr class="mt-3 mb-3" /> -->
                                <div>
                                    <div class="col-lg-12" *ngIf="slots.length > 0">
                                        <div class="mb-3">
                                            <label class="form-label">Time Slot Preference #1 : <span
                                                    class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="clock" class="fea icon-sm icons"></i-feather>
                                                <select class="form-control ps-5" aria-label="Default select example"
                                                    formControlName="timePrefOne" name="timePrefOne" id="slotOne"
                                                    [ngClass]="{ 'is-invalid': submitted && form['timePrefOne'].errors }">
                                                    <option></option>
                                                    <option *ngFor="let slot of slots" [value]="slot">
                                                        {{slot}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && form['timePrefOne'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="form['timePrefOne'].errors">Please Select A Slot.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="slots.length > 0">
                                        <div class="mb-3">
                                            <label class="form-label">Time Slot Preference #2 : <span
                                                    class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="clock" class="fea icon-sm icons"></i-feather>
                                                <select class="form-control ps-5" aria-label="Default select example"
                                                    formControlName="timePrefTwo" name="timePrefTwo" id="slotTwo"
                                                    [ngClass]="{ 'is-invalid': submitted && form['timePrefTwo'].errors }">
                                                    <option></option>
                                                    <option *ngFor="let slot of slots" [value]="slot">
                                                        {{slot}}
                                                    </option>
                                                </select>
                                                <div *ngIf="submitted && form['timePrefTwo'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="form['timePrefTwo'].errors">Please Select A Slot.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <hr class="mt-3 mb-3" /> -->
                                </div>
                                <div class="p-4" *ngIf="slots.length > 0">

                                    <div role="alert" class="alert alert-info"><i-feather name="edit"
                                            class="fea icon-sm icons"></i-feather> This data is used for planning
                                        purpose only,
                                        You are allowed to play in other slots based on availability!</div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Fee : <span class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="dollar-sign" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="" name="fee"
                                                required="" formControlName="fee"
                                                [ngClass]="{ 'is-invalid': submitted && form['fee'].errors }" readonly>
                                            <div *ngIf="submitted && form['fee'].errors" class="invalid-feedback">
                                                <div *ngIf="form['fee'].errors">Fee Is Mandatory.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="p-4">

                                    <div role="alert" class="alert alert-info"><i-feather name="edit"
                                            class="fea icon-sm icons"></i-feather> Please refer
                                        the <a href="javascript:void(0)" (click)="Subscribeopen(faqContent)"
                                            class="alert-link">FAQ document</a> for instructions on payment!</div>
                                </div> -->
                                <!-- <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="form-label">Transaction Ref #: <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="file-minus" class="fea icon-sm icons"></i-feather>
                                            <input type="text" class="form-control ps-5" placeholder="" name="transRef"
                                                required="" formControlName="transRef"
                                                [ngClass]="{ 'is-invalid': submitted && form['transRef'].errors }">
                                            <div *ngIf="submitted && form['transRef'].errors" class="invalid-feedback">
                                                <div *ngIf="form['transRef'].errors">Transaction Reference Number Is
                                                    Mandatory.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <hr> -->

                                <div>
                                    <div class="p-4">
                                        <div class="form-check form-switch" *ngIf="selectedData.sport === 'Badminton'">
                                            <input type="checkbox" id="birdieAgreement" class="form-check-input"
                                                formControlName="birdieAgreementChkbx"
                                                [ngClass]="{ 'is-invalid': submitted && form['birdieAgreementChkbx'].errors }">
                                            <label for="birdieAgreement" class="form-check-label">I understand that
                                                Badminton Racquets and Birdie (No Plastic Birdie) are player's
                                                responsibility, NISA organization will not provide them.
                                            </label>
                                            <div *ngIf="submitted && form['birdieAgreementChkbx'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form['birdieAgreementChkbx'].errors">Please agree to the
                                                    terms.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-check form-switch" *ngIf="selectedData.sport === 'Badminton'">
                                            <input type="checkbox" id="faqAgreement" class="form-check-input"
                                                formControlName="faqAgreementChkbx"
                                                [ngClass]="{ 'is-invalid': submitted && form['faqAgreementChkbx'].errors }">
                                            <label for="faqAgreement" class="form-check-label">I have read the
                                                <a href="javascript:void(0)" (click)="Subscribeopen(faqContent)"
                                                    class="alert-link">FAQ document</a>
                                                provided on the website
                                                and am in agreement with the
                                                details provided.
                                            </label>
                                            <div *ngIf="submitted && form['faqAgreementChkbx'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form['faqAgreementChkbx'].errors">Please agree to the terms.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-check form-switch">
                                            <input type="checkbox" id="waiverAgreement" class="form-check-input"
                                                formControlName="waiverAgreementChkbx"
                                                [ngClass]="{ 'is-invalid': submitted && form['waiverAgreementChkbx'].errors }">
                                            <label for="waiverAgreement" class="form-check-label">I have read the
                                                <a href="javascript:void(0)" (click)="Subscribeopen(waiverContent)"
                                                    class="alert-link">Waiver document</a> provided on the website and
                                                am
                                                in agreement with the
                                                details provided.
                                            </label>
                                            <div *ngIf="submitted && form['waiverAgreementChkbx'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="form['waiverAgreementChkbx'].errors">Please agree to the
                                                    terms.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button *ngIf="!displayPayPalButtons" class="btn btn-primary">Register</button>
                                    <div *ngIf="displayPayPalButtons" id="paypal-buttons"></div>
                                </div>
                                <!--end col-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
</section>


<ng-template #faqContent let-modal>
    <div style="height: 600px">
        <ng2-pdfjs-viewer [original-size]='true' [fit-to-page]='true'
            pdfSrc="NISA - Badminton FAQ's V2.0.pdf"></ng2-pdfjs-viewer>
    </div>
</ng-template>
<ng-template #waiverContent let-modal>
    <div style="height: 600px">
        <ng2-pdfjs-viewer pdfSrc="NISA - WAIVER AND RELEASE OF LIABILITY.pdf"></ng2-pdfjs-viewer>
    </div>
</ng-template>